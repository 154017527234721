*{
  margin: 0px ;
  padding: 0px;
  box-sizing: border-box;
  font-family: Inter, system-ui;
  color: #3A3A1F;
  scroll-behavior: smooth;
}
.plaz{
  display: none;
}
.plaz2{
  color: #3A3A1F;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
  display: flex;
  padding: 16px 36px;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  background: rgba(58, 58, 31, 0.20);
  backdrop-filter: blur(7px);
  position: absolute;
  top: -1em;
  left: 50%;
}

.flat{
  margin-top: 150px;
}
.black{
  color: black !important;
}
h3{
  font-size: 32px ;
}
.sdc{
  margin-top: 120px;
}
h2{
  font-size: 64px;
}
html{
  font-size: 18px;
}
img{
  border-radius: 20px;
}
body{
  overflow-x: hidden;
}

.header{
  display: grid;
  grid-column-gap: 20px;
  grid-template-columns: 1fr 4fr 1fr;
  width: auto;
  font-weight: 400;
  line-height: 24px;
  border-radius: 20px;
  margin-bottom: 20px;
  box-shadow: 0px 4px 45px 0px rgba(217, 217, 217, 0.25);
  position: fixed;
  top: 15px;
  left: 35px;
  right: 35px;
  padding: 15px 35px 15px 35px;
  z-index: 9999;
  background: white;
}
.main_block{
  display: flex;
  align-items: center;
}
html{
  overflow-x: hidden;
}
.call_button{
  border-radius:35px;
  border: none;
  background: #1B9217 ;
  display: flex;
  align-items: center;
  padding: 12px 24px 12px 24px;
  color: white;
  font-size: 20px;
  font-weight: 400;

}
.call_button img{
  margin-right:10px;
}
.main_menu{
  display: flex;
  align-items: center;
  justify-content: center;
}
.main_menu span{
  margin: 0px 20px;
}
.about_company{
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  margin: 107px 0;
  max-height: 70vh;
}
.about_company p{
  font-size: 20px;
  width: 90%;
  margin-bottom: 25px;
}
.about_company h2{
  margin-bottom: 20px;
  margin-top: 30px;
  line-height: 77px;
}
.about_company_img img{
  width: 100%;
}
.ab_company{
  margin: 168px 0px;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.ab_company h2{
  margin-bottom: 20px;
  margin-top: 40px;
}
.ab_company img{
  width: 100%;
}
.ab_company p{
  font-size: 20px;
  width: 75%;
  margin-bottom: 25px;
}
.perev{
  display: grid;
  grid-template-columns: 2fr 1fr;
  margin: 50px 0;
  grid-gap: 5%;
}
.perev h2{
  font-size: 30px;
  margin: 5px 0;
}
.perev p{
  font-size: 18px;
  font-weight: 400;
}
.perev img{
  width: 100%;
}
.perev2{
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-gap: 5%;
  margin: 64px 0;
  font-size: 18px;
}
.date_bud{
  position: absolute;
  z-index: 10;
  margin-top: 30px;
  margin-left: 50px;
  border-radius: 50px;
  background: rgba(0, 0, 0, 0.20);
  backdrop-filter: blur(7px);
  padding: 11px 30px;
  color: white;
}
.progress_head h2{
  margin: 48px 10px;
}
.clickk, .ckickk{
  top: 50%;
  transform: translate(0,-50%);
  cursor: pointer;
}
.flat_1f img{
  border: 2px solid #A9A99D;
}
.dat_bud{
  position: absolute !important;
  z-index: 10;
  margin-top: 36px;
  margin-left: 36px;
  border-radius: 50px;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  background: rgba(58, 58, 31, 0.20);
  backdrop-filter: blur(7px);
  padding: 11px 30px;
  color: black;
}
.perev2 p{
  margin-bottom: 20px;
  width: 90%;
}
.perev2 h2{
  font-size: 30px;
  margin: 10px 0;
}
.perev2 img{
  width: 100%;
}
.about_bud{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  width: 100%;
  margin-top: 60px;
}
.about_bud div{
  margin: 10px;
}
.about_bud p{
  width: 90%;
}
.about_bud img{
  width: 100%;
}
.about_bud h2{
  margin-bottom: 20px;
}
.flat .slick-slide img {

}
.call_block{
  width: 100%;
  display: flex;
  justify-content: center;
}
.flat{
  width: 100%;
  font-size: 27px;

}
.perev span{
  font-size: 16px;
}
.zak{
  border-radius: 20px 0 0 20px !important;
  border-top: 2px solid var(--Gray, #A9A99D);
  border-left: 2px solid var(--Gray, #A9A99D);
  border-bottom: 2px solid var(--Gray, #A9A99D);
}
.zak2{
  border-radius: 0px 20px 20px 0px !important;
  border-top: 2px solid #A9A99D;
  border-right: 3px solid #A9A99D;
  border-bottom: 2px solid #A9A99D;
}
.perev2 span{
  font-size: 16px;
}
.flat .zak img{
  border-radius: 17px 0 0 17px;
  width: 100%;
}
.flat .zak2 img{
  border-radius: 0 17px 17px 0;
  width: 100%;
}
.full-width{
  width: 100%;
}
.full-width img{
  width: 100%;
}
.gallery{
  margin-top: 40px;
  width: 120%;
  height: 50vh;
margin-left: -5%;
  display: flex;
  align-items: end;

}
.gallery1{
  width: 120%;
  height: 50vh;
  margin-left: -5%;
  display: flex;
  align-items: start;

}
.img1{
  max-height: 75%;
}
.img2{
  width: 25%;
  max-height: 50%;

}
.img3{
  width: 35%;
  max-height: 100%;
}
.img4{
  width: 30%;
  max-height: 70%;

}
.img5{
  width:25%;
  max-height: 60%;
}

.img6{
  width: 30%;

}
.img7{
  width: 20%;
  max-height: 100%;
}
.img8{

  max-height: 70%;

}
.gallery img, .gallery1 img{
  margin: 15px;
}
.progress{
  width: 100%;
}
.progress_head{
  display: flex;

}
.progress_gallery{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 20px;
}
.progress_gallery img{
  width: 100%;
}
.prog_reg div{
  display: none;
  align-items: center;
}
.faq_block div{

  font-weight: 500;
  background: white;
  font-size: 32px;

}
.q.p{
  font-size: 20px;
}
hr{
  margin-top: 9px;
  color: #A9A99D;
  background: #A9A99D;
}
.mob_y{
  display: none;
}
.faq_question{
  margin: 30px 0;
  z-index: 9;
  position: relative;
  cursor: pointer;
}
.m{
  border: none;
}
.faq_plus{
  width: 32px;
  margin-bottom: -2px;
  margin-left: auto;
 float: right;
  transition: 0.5s;

}
.rotate_plus{
  transform: rotate(45deg);
  width: 32px;
}
.faq_block{
  transition: 0.5s;
margin-top: 20px;
  font-size: 18px;
}
.contacts{
  display: grid;
  grid-template-columns: 1fr 3fr;
  margin-top: 100px;
}
.footer{
  display: grid;
  grid-template-columns: 2fr 1fr 1fr 1fr;
  margin-top: 80px;
}
.footer_main{
  display: flex;
  align-items: center;
  justify-content: start;
}

.footer div{
  margin-bottom: 5px;
}
.krest{
  position:absolute;
  top:10px;
  right:10px;
}
.q{

  opacity: 0;
  transition: opacity 0.2s, height 1s, margin  0.5s;
  height: 0;
  font-size: 15px;
  border: transparent;
  visibility: hidden;
}
.modal_content.imgp img{
  max-height: 80vh;
}
.modal_content.imgp{
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  max-height: 95vh;
}
.mob_gallery img{
  pointer-events: auto;
}
.p{
  visibility: visible;
  height: 2em;
  font-size: 15px;
  opacity: 1;
  margin: 20px 0;
  transition: opacity 0.2s 0.2s,height 1s, margin  0.5s;
}
.wsx{
  position: absolute;
  font-size: 20px;
text-align: center;
  z-index: 999;
  left: 50%;
  top: 10%;
  transform: translate(-50%);
  color: white;
  font-weight: 450;

}
p{
  font-size: 18px;
}
iframe{
  border-radius: 20px;
  border: none;
}
.mob_flat{
  display: none;
}
.slick-slider.slick-initialized{
  border-radius: 20px;
}
.anim{
  animation: qwe 0.5s;
}
@keyframes qwe {
  0%{opacity: 1;}
  50%{opacity: 0;}
  100%{opacity: 1;}
}
.main_gallery div{
  transition:0s;
}
.flat_1f img{
  width: 100%;
}
.flat_1f{
  position: relative;
}
.for_pl div{
position: relative;

}
.for_pl{
  width: 100%;
  position: absolute !important;
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.blur{
  padding: 16px 36px;
  border-radius: 50px;
  background: rgba(255, 255, 255, 0.20);
  backdrop-filter: blur(7px);
  margin-left: 50%;
  width: 60%;
  font-weight: 400;
  transform: translate(-50%);
  color: white;

}

.under_blur{
  font-size: 110px;
  margin-top: 30px;
  color: white;
  font-weight: 600;
}
.main_banner_block button{
  z-index: 9999;
}
.pp{
  margin-bottom: 30px
}
.w75{
  width: 75%;
}
button[data-id="bullet-0"], button[data-id="bullet-1"], button[data-id="bullet-2"]{
  z-index: 9999;
  position: relative;
}
.qw{
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 999;
  background: rgba(0,0,0,0.3);
}
.mar_fon{
  font-size:20px;
  margin-right:70px;
}
.mobile_slider{
  display: none;
}
.dat_bud{
  font-size: 20px;
}
.main_banner_block{
  width: 100%;
  position: relative;
  border-radius: 20px;
  overflow: hidden;
  margin-top: 128px;
  height: calc(100vh - 172px);

}
.mar_fon1 img, .mar_fon img{
  filter: opacity(0.2);
}
.mar_fon1.black img, .mar_fon.black img{
  filter: opacity(1);
}
.main_banner_block img{
 background-position: center center;
  height: 100%;
}
.main_banner_block div{
  height: 100%;
}
body{
  padding: 15px 35px;
}
.call_button:hover{
  background:#27B822;
}
.call_button:active{
  background:#188114;
}
.gallery_slider img{
  width: 95%;



}
.gallery_slider .slick-list img{
  margin-left: 50%;
  transform: translate(-50%);
}
.call_button.mod:hover{
  border: 1px solid #1B9217;
  background: #F2FFF1;
}
.call_button.mod:active{
  border: 1px solid #1B9217;
  background: #E3FFE2;
}
input[type = "text"]{
  border: 1px solid #DEDEDE;
}

input[type = "text"]:hover{
  border: 1px solid #989898;
}

input[type = "number"]{
  border: 1px solid #DEDEDE;
}

input[type = "number"]:hover{
  border: 1px solid #989898;
}

input[type = "number"]:focus{
  border: 1px solid #3A3A1F;
}
input[type = "text"]:focus{
  border: 1px solid #3A3A1F;
}
input[type = "number"]:active{
  border: 1px solid #3A3A1F;
}
input[type = "text"]:active{
  border: 1px solid #3A3A1F;
}
.App{

}
.check {
  -webkit-appearance: none; /*hides the default checkbox*/
  height: 20px;
  width: 20px;
  margin-bottom: -4px;
  transition: 0.10s;
  margin-right: 10px;
  text-align: center;
  font-weight: 600;
  color: white;
  border-radius: 3px;
  outline: none;
  border: 1px solid black;
}

.check:checked {
  background-color: #0E9700;
}

.check:before {

}
.check:checked:before {
  content: "✔";
}

.check:hover {
  cursor: pointer;
  opacity: 0.8;
}
.main_gallery{
  width: 100vw;
  overflow: hidden;
  margin-left:50%;
  transform: translate(-50%);
}
.mob_gallery{
  display: none;
}
.modal{
  height: 100vh;
  width: 100vw;
  background: rgba(0,0,0, 0.4);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  pointer-events: none;
  transition: 0.5s;


}
.imgp{
  padding: 15px 15px;
  border-radius: 12px;
  background: white;
  width: auto !important;
  height: auto;
  top: 26%;
  position: relative;
  transform: translate(0,-50%);

}
.imgp1{
  padding: 15px 15px;
  border-radius: 12px;
  background: white;
  width: auto !important;
  height: auto;
  top: 44%;
  position: relative;
  transform: translate(0,-50%);
  overflow-x: hidden;

}
.galka{
  margin-left: 50%;
  transform: translate(-50%);
  margin-top: 20px;
  width: 70%;
}
.modal_content{
  padding: 15px 20px;
  border-radius: 12px;
  background: white;
  width: 35%;
  text-align: center;
  color: #A9A99D;


}
.contacts_mobile_main{
  display: none;
}
.modal.active{
  z-index: 999999;
  opacity: 1;
  pointer-events: all;
}
.prog_reg{
  margin-left: auto;
  display: flex;
}
.prog_reg div{
  font-size: 32px;
  font-weight: 600;
}
.backline span{
  display: inline-block;
  position: relative;

}
.mod{
  background: white;
  border: 1px solid #1B9217;
  color: #1B9217;
}
.variant_flat{
  position: absolute;
  top: 35px;
}
.backline span::before {
  content: '';
  position: absolute;
  left: 0;
  width: 100%;
  margin-top: 13px;
  height: 1px;
  background-color: #30230c;
}
.mod_n{
  display: block;
  border-radius: 20px;
}
input[type = "number"]{
  width: 80%;
  border-radius: 10px;
  border: 1px solid #DEDEDE;
  padding: 12px 24px;
}
input[type = "text"]{
  width: 80%;
  border-radius: 10px;
  border: 1px solid #DEDEDE;
  padding: 12px 24px;
}
.footer_main.desk{
  display: none;
}
.mobile_menu{
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.mobile_menu a{
  color: black !important;
  font-weight: bold !important;
  margin: 20px 0;
}
.mobile_menu div div{
  margin: 10px 0;
}
.prog_reg img{
  margin: 16px;
}
button{
  cursor: pointer;
}
.mod_y{
  display: none;
}
.menu-btn{
  width: 36px;
  height: 36px;
  right: 40px;
  position: absolute;
  display: none;
}
.menu-btn span{
  display: block;
  background: black;
  height: 3px;
  margin-top: 10px;
  margin-left: auto;
}
a{
  text-decoration: none;
  font-weight: 500;
  font-size: 20px;
}
.ab_company_info{
  margin-left: 40px;
}
.ab_company_info p {
  margin-bottom: 30px;
}
@media screen and (max-width: 768px){
  .modal_content.imgp{
    top:36%;
  }
  .mob_flat {
    display: block;
    border-radius: 24px;
    border: 2px solid #d5d5cf;
  }
  .plaz{
    display: none;
    padding: 8px 16px;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
    background: rgba(58, 58, 31, 0.20);
    backdrop-filter: blur(7px);
    color: #3A3A1F;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;
    margin-top: 48px;
    width: 25%;
    margin-left: 50%;
    transform: translate(-50%);
  }
  .header_call_button, .main_menu{
    display: none;
  }
  .main_gallery{
    display: none;
  }
  .about_company{
    grid-template-columns: 1fr;
  }
  .perev{
    grid-template-columns: 1fr;
    grid-gap: 4%;
  }
  .perev2{
    grid-template-columns: 1fr;
  }
  .about_company h2{
    font-size: 40px;
  }
  .ab_company{
    grid-template-columns: 1fr;
  }
  .mob_y{
    display: block;
    width:100%;
    overflow: hidden;
  }

  .perev2{
    grid-gap: 0;
  }
  .ab_company_info{
    margin: 0;
  }
  .krest{
    right: 0px;
    top: 0px;
  }
  .mod_y{
    display: block;
  }
  .pp {
    margin-bottom: -40px
  }
  .p{
    height: 4em;
  }
  .po{
    height: 7em;
    font-size: 15px;
    opacity: 1;
    margin: 20px 0;
    transition: opacity 0.2s 0.2s,height 1s, margin  0.5s;
  }
  .ppq{
    position: absolute;
    bottom: 0;
  }
  .pop{
    height: 8em;
    font-size: 15px;
    opacity: 1;
    margin: 20px 0;
    transition: opacity 0.2s 0.2s,height 1s, margin  0.5s;
  }
  .mod_n{
    display: none;
  }
  .call_button{
    font-size: 15px;
  }
  .contacts_mobile{
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  .contacts{
    display: none;
  }
  .contacts_mobile_main{
    margin-top: 70px;
  }
  .contacts_mobile_main {
    display: block;
  }
  .contacts_mobile h3{
    font-size: 20px;
    margin: 18px 0 12px 0;
  }
  .contacts_mobile p{
    margin: 8px 0;
  }
  .qwers{
    margin: 24px;
  }
  .contacts_mobile_main button{
    margin: 40px 8px;
  }
  .contacts_mobile h2{
    font-size: 24px;
  }
  .contacts_mobile{
    grid-template-columns: 1fr;
    text-align: center;
  }
  .modal{

    align-items: start;
  }
  .modal_content{
    width: 95%;
  }
  .gallery_slider{
    width: 90%;
  }
  .footer{
    margin-top: 40px ;
    grid-gap: 20px;
    grid-template-columns: 1fr 1fr 1fr;
  }
  .footer_main{
    margin-top: 25px;
  }
  .slick-list{
    overflow: visible !important;
  }
  .desk{
    display: none;
  }
  .mobile_slider{
    display: block;
    width: 90%;
  }
  .mob_gallery{
    display: block;
    width: 90%;
  }
  .slick-next:before{
    display: none;
  }
  .date_bud{
    font-size: 10px;
    padding: 8px 16px;
    top: 0px;
    margin-left: 50px;
  }
  .footer_main.desk{
    display: flex;
  }
   .footer a{
     font-weight: 300;
   }
   .modal_content{
     overflow-y: scroll;
     overflow-x: hidden;
     width: calc(100% - 26px);
     max-height: 100vh;
     margin-top: 10px;
   }
  .modal_content a{
    color: #1B9217;
  }
  .mobile_slider h2{
    font-size: 20px;
  }
  .mobile_slider p{
    padding: 3px;
  }
  .about_bud{
    display: none;
  }
  .prog_reg{
    display: none;
  }
  .gallery img, .gallery1 img{
    width: auto !important;
  }

  .gallery1 img{
    max-height: 50%;
  }
  .qwert{
    font-size: 24px;
    margin: 42px 0 90px 0;
    text-align: center;
  }
  .blur{
    padding: 8px 16px;
    font-size: 10px;
  }
  .main_banner_block .clickk, .main_banner_block .ckickk{
    top:60%;
  }
  .under_blur{
    font-size: 30px;
  }
  .menu-btn{
    right: 20px;
  }
  .variant_flat{
    position: relative;
    margin-top: 30px;
    display: flex;
    justify-content: center;
  }
  .mar_fon{
    font-size: 20px;
    margin-right: 20px;
  }
  .flat{
    margin-top: -40px;
  }
}
@media screen and (min-width: 768px){
  .modal_content{
    min-width: 600px;
  }
  .wer{
    display: none;
  }
  .header img[alt="home"]{
  width:85%;
  }
  .p{
    height: 4em !important;
  }
}
.gallery_slider.mod_n .slick-track img{
  aspect-ratio: 300/260;
}
@media screen and (max-width: 480px){
  .gallery_slider .slick-list img {
    margin-left: unset;
    transform: none;
  }

  .gallery_slider.mob_y{
    overflow: visible;
  }
  .footer{
    grid-template-columns: 1fr;
  }
  .dat_bud{
    margin-left: 16px;
    margin-top: 16px;
  }
  .mob_flat{
    display: block;
    width: 100%;
    overflow: hidden;
  }
  .mob_flat img{
    width: 100%;
  }
.variant_flat{
  top: 5px;
}
.flat img{
  border-radius: 0px;
}
  .blur{
    width: 65%;
  }
  .backline div{
    font-size: 11px;
  }
  .modal_content .call_button a{
    font-size: 15px;
  }

  .po {
    height: 8em !important;
  }
  .mobile_menu{
    grid-template-columns: 1fr;
  }
  body{
    padding: 15px 12px;

  }
  .progress_head h2{
    margin: 24px 12px;
  }
  .main_banner_block{
    margin-top: 92px;
  }
  .gallery_slider img {
     width: 90%;
  }


  .qws{
    margin-bottom: 6px;
  }
  .sdc{
  margin-top: 36px;
  }
  .mob_gallery{
    margin-bottom: 46px;
  }
  .main_block img[alt="home"]{
    height: 26px;
    width: 141px;
  }
  .header{
    height: 64px;
  }
  .header{
    top: 12px;
    left: 12px;
    right: 12px;

    padding: 20px 16px;
  }
  .full-width p{
    width: 100% !important;
  }
  .full-width{
    margin-top: -32px;
  }
  h1{
    font-size: 30px;
  }
  .menu-btn span{
    margin-top: 5px;

  }
  .main_block .qqw{
    margin-top: 7px;
    margin-left: -5px;
  }
  .menu-btn{
    margin-top: 18px;
  }
  .ab_company{
    margin: 72px 0;
  }
  h2{
    font-size: 24px !important;
  }
  .w75{
    width: 100%;
  }
  .ab_company p{
    width: 100%;
  }
  .ab_company .call_button{
    width: 100%;
    font-weight: 600;
    justify-content: center;
    font-size: 16px;
  }
  .call_button{
    width: 100%;
    font-weight: 600;
    justify-content: center;
    font-size: 16px;
  }
  .clickk, .ckickk{
    top:50%;
  }
  .perev h3{
    margin: 16px 0 12px 0;
    font-size: 20px;
  }
  .gallery_slider.mob_y.qws .date_bud{
    margin-top: 16px !important;
    margin-left: 16px !important;
  }
  .gallery_slider.mob_y.qws img{
  pointer-events: none;
}
  .dat_bud{
    font-size: 10px !important;
  }
  .perev span{
    font-size: 10px;
  }
  .perev2 h3{
    margin: 16px 0 12px 0;
    font-size: 20px;
  }
  .perev2 span{
    font-size: 10px;
  }
  .full-width h3{
    margin: 16px 0 12px 0;
    font-size: 20px;
  }
  .full-width span{
    font-size: 10px !important;
  }
  .mobile_slider h3{
    margin: 16px 0 12px 0;
    font-size: 20px;
  }
  p{
    font-size: 13px !important;
  }
  .mobile_slider span{
    font-size: 10px;
  }
  .main_gallery img{
    width: 40%;
    height: auto;
  }
  .faq_block div{
    font-size: 16px;
  }
  .rotate_plus{
    width: 24px;
    height: 24px;
  }
  .faq_plus{
    width: 24px;
    height: 24px;
    position: absolute;
    right: 0;
  }

  .q.p{
    font-size: 13px;
  }
  input[type = "number"]{
    width: 100%;
  }

  input[type = "text"]{
    width: 100%;
  }
  .modal_content button{
    width: 100%;
  }
}
@media screen and (max-width: 1185px){
  .header .call_button{
    display: none;
  }
  .main_menu span{

    display: none;
  }
  .menu-btn{
    display: block;
    margin-top: 18px;
  }
}

@media screen and (max-width: 1000px) {
  .main_menu span{
    margin: 0 10px;
    text-align: center;
  }
  .wsx{
    width: 75%;
  }
  .flat h1{
    font-size: 40px;
  }
  .footer_main{
    margin-top: 90px;
  }
}
@media screen and (max-width: 1600px){
  .main_menu a{
    font-size: 14px;
  }
  .dat_bud{
    font-size: 15px;
  }
  h3{
    margin-bottom: 10px;
  }
  .header_call_button button{
    font-size: 14px;
  }
  .main_banner_block{
    height: calc(100vh - 20px);
  }
  .sdc{
    margin-top: 40px;
  }
  .full-width span, .about_bud span{
    font-size: 16px;
  }
  h2 {
    font-size: 50px;
  }

  .flat .clickk, .flat .ckickk{
    top: 50%;
  }
  .wer{
    display: block !important;
    margin: 120px 0px 0px 10px !important;
  }
  .main_gallery{

  }
}
@media screen and (min-width: 1400px) and (max-width: 1600px){
  .plaz2{
    position: absolute;
    top: -1em;
    left: 23%;
  }
  .flat{
    margin-top: 150px;
  }
}
@media screen and (min-width: 1601px) and (max-width: 1800px){
  .plaz2{
    position: absolute;
    top: -1em;
    left: 25%;
  }
  .flat{
    margin-top: 150px;
  }
}
@media screen and (min-width: 1801px) and (max-width: 2000px){
  .plaz2{
    position: absolute;
    top: -1em;
    left: 23%;
  }
  .flat{
    margin-top: 150px;
  }
}
@media screen and (min-width: 1300px) and (max-width: 1401px){
  .plaz2{
    position: absolute;
    top: -1em;
    left: 26%;
  }
  .flat{
    margin-top: 150px;
  }
}
@media screen and (max-width: 1300px) and (min-width: 1200px){
  .plaz2{
    position: absolute;
    top: -1em;
    left: 28%;
  }
  .flat{
    margin-top: 150px;
  }
}
@media screen and (max-width: 1200px) and (min-width: 1150px){
  .plaz2{
    position: absolute;
    top: -1em;
    left: 30%;
  }
  .flat{
    margin-top: 150px;
  }
}
@media screen and (max-width: 1150px){
  .plaz2{
    margin-left: 50%;
    transform: translate(-50%);
    top: -4em;
    left: 0;
  }
  .flat{
    margin-top: 150px;
  }
}