
.slider {
  position: relative;
  height: 500px;
  max-width: 100%;
  margin: 3.125em auto;
  overflow: hidden;
}
.slider__img-container {
  transition: transform 1s;
}
.slide {
  float: left;
  height: 500px;
}
.slide__img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.slider__arrow-nav-container {
  position: relative;
  width: 100%;
  height: 500px;
  max-width: 800px;
}
.slider__arrow-nav-container.is-active {
  cursor: grabbing;
}
.arrow {
  display: block;
  position: absolute;
  top: 50%;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.7);
  cursor: pointer;
}
.arrow::before {
  box-sizing: border-box;
  content: '';
  display: block;
  position: absolute;
  top: 12px;
  width: 16px;
  height: 16px;
  border-top: 5px solid #3498db;
}
.arrow--prev {
  left: 25px;
}
.arrow--prev::before {
  left: 15px;
  border-left: 5px solid #3498db;
  transform: rotate(-45deg);
}
.arrow--next {
  right: 25px;
}
.arrow--next::before {
  right: 15px;
  border-right: 5px solid #3498db;
  transform: rotate(45deg);
}
.dot-container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 25px;
  left: 0;
}
.dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin: 0 0.2em;
  background: lightgray;
  cursor: pointer;
}
.dot.is-active {
  background: #3498db;
}
 